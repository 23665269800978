
import { defineComponent } from "vue";
import Vue3Html2pdf from "vue3-html2pdf";
import _ from "lodash";
import helpers from "@/helpers/global";
import Audit from "./MainCoreValuesAnalyzer/Audit.vue";
import Skills from "./MainCoreValuesAnalyzer/Skills.vue";
import Questions from "./MainCoreValuesAnalyzer/Questions.vue";
import Microlearning from "./MainCoreValuesAnalyzer/Microlearning.vue";
import { ElMessage } from "element-plus";

const __default__ = defineComponent({
  name: "MainCoreValuesAnalyzer",
  components: {
    Audit,
    Skills,
    Questions,
    Microlearning,
    Vue3Html2pdf,
  },
  computed: {
    companyLogoUrl() {
      const defaultLogoUrl = "";
      const companyLogoUrl: string = this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl || defaultLogoUrl;
    },
    companyColor(): string {
      const defaultCompanyColor = "#000000";
      const user: any = this.$store.getters["user/user"] || {};
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
    // @see src\components\MainCoreValuesAnalyzer\Audit.vue
    analyzerLoading(): boolean {
      return this.$store.getters["coreValuesAnalyzer/loading"];
    },
    isLightColor(): any {
      const user = this.$store.getters["user/user"] || {};
      const color = user.color || "#000";
      return helpers.isLightColor(color);
    },
    isLoadedAllData(): boolean {
      const overallData = this.$store.getters["coreValuesAnalyzer/auditData"];
      const overallDataLoaded = _.isObject(overallData);
      const skillLoaded =
        this.$store.getters["coreValuesAnalyzer/skillsLoaded"];
      const questionLoaded =
        this.$store.getters["coreValuesAnalyzer/questionsLoaded"];
      const learningLoaded =
        this.$store.getters["coreValuesAnalyzer/microlearningLoaded"];
      const loaded =
        overallDataLoaded && skillLoaded && questionLoaded && learningLoaded;
      return loaded;
    },
  },
  data() {
    return {
      coreValue: [
        {
          value: "",
        },
      ],
      coreValueTags: [
        {
          label: "Purpose-driven",
          id: "purpose-driven",
        },
        {
          label: "Human-centric",
          id: "human-centric",
        },
        {
          label: "Disruptive thinking",
          id: "disruptive_thinking",
        },
        {
          label: "Customer obsession",
          id: "customer-obsession",
        },
        {
          label: "Bold experimentation",
          id: "bold-experimentation",
        },
        {
          label: "Continuous learning",
          id: "continuous_learning",
        },
        {
          label: "Radical transparency",
          id: "radical_transparency",
        },
        {
          label: "Growth-mindset",
          id: "growth-mindset",
        },
      ],
      analyzerShowing: false,
      lockedForLogin: true,
      tabSelected: "audit",
      tabs: [
        {
          label: "Audit",
          id: "audit",
          height: 0,
          ref: "audit_section",
          activeTab: false,
        },
        {
          label: "Skills",
          id: "skills",
          height: 0,
          ref: "skills_section",
          activeTab: false,
        },
        {
          label: "Survey_Question",
          id: "survey_questions",
          height: 0,
          ref: "questions_section",
          activeTab: false,
        },
        {
          label: "Microlearning",
          id: "microlearning",
          height: 0,
          ref: "microlearning_section",
          activeTab: false,
        },
      ],
      scrollPosition: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateActiveByScroll);
    if (this.$router.currentRoute.value.meta.auth) {
      this.lockedForLogin = false;
      this.$store.commit("coreValuesAnalyzer/isLoggedIn", true, { root: true });
    }
    this.init();
  },
  methods: {
    onProgress($event: any) {
      console.log("onProgress()");
      console.log($event, "$event");
    },
    hasStartedGeneration() {
      console.log("hasStartedGeneration()");
    },
    hasGenerated($event: any) {
      console.log("hasGenerated()");
      console.log($event, "$event");
    },
    async beforeDownload($event: any) {
      // console.log("beforeDownload()");
      const { options, pdfContent } = $event;
      let clientHeight = pdfContent.clientHeight;
      // clientHeight *= options.html2canvas.scale;
      // clientHeight += 1500;
      // clientHeight += 200;

      options.compress = true;
      options.jsPDF = {
        unit: "px",
        // format: "legal",
        // format: [1082, Math.round(clientHeight)],
        format: [1440, Math.round(clientHeight)],
        orientation: "p",
        // putOnlyUsedFonts: true,
      };
      options.filename = "My Core Values.pdf";

      // options.html2canvas.letterRendering = true;
      // options.html2canvas.useCORS = true;
      // options.html2canvas.logging = true;
      // console.log(options, "options");
    },
    downloadPdf() {
      // console.log("downloadPdf()");
      // console.log(this.$refs.html2Pdf, "html2Pdf");
      ElMessage({
        message: (this as any).$t("coreValuesAnalyzer.Download_pdf_success"),
        type: "success",
      });
      (this.$refs.html2Pdf as any).generatePdf();
    },
    clickToTab(tab: { label: string; id: string; height: number }) {
      this.tabSelected = tab.id;
      if (this.analyzerShowing && !this.analyzerLoading) {
        window.scrollTo(0, tab.height + 2);
        this.updateActiveByScroll();
      }
    },
    updateActiveByScroll() {
      this.scrollPosition = window.scrollY;
      if (this.analyzerShowing) {
        for (const i in this.tabs) {
          const tabHeight = this.tabs[i].height;
          const value = (Number(i) + 1).toString();
          const thisLastValue = value == "4" ? true : false;
          if (!thisLastValue) {
            if (
              this.scrollPosition > tabHeight &&
              this.scrollPosition < this.tabs[Number(value)].height
            ) {
              this.tabs[i].activeTab = true;
            } else {
              this.tabs[i].activeTab = false;
            }
          } else if (thisLastValue && this.scrollPosition > tabHeight) {
            this.tabs[i].activeTab = true;
          } else {
            this.tabs[i].activeTab = false;
          }
        }
      }
    },
    addInputOnEnter(index: number) {
      // this.coreValue
      if (index < 8) {
        this.coreValue.push({
          value: "",
        });
        const ref = "input_core_value_" + index;
        setTimeout(() => {
          const input = (this as any).$refs[ref];
          if (input) {
            (document as any).getElementById(ref).focus();
          }
        }, 100);
      }
    },
    addTagToCoreValue(label: string) {
      const index = this.coreValue.length;
      this.removeNullValue();
      const added = this.coreValue.filter((d) => {
        return d.value === label;
      });
      if (index < 8 && !added.length) {
        this.coreValue.push({
          value: label,
        });
      } else if (added) {
        this.coreValue = this.coreValue.filter((d) => {
          return d.value !== label;
        });
        if (this.coreValue.length === 0) {
          this.coreValue.push({
            value: "",
          });
        }
      }
    },
    removeNullValue() {
      this.coreValue = this.coreValue.filter((item) => item.value !== "");
    },
    async analyze() {
      // console.log("analyze()");

      // filter input (values)
      let values = [];
      for (const i in this.coreValue) {
        const value = _.trim(this.coreValue[i].value);
        if (!value.length) continue;
        values.push(value);
      }
      values = _.uniq(values);
      await this.$store.dispatch("coreValuesAnalyzer/analyze", values, {
        root: true,
      });
      // const newAuditData = this.$store.getters["coreValuesAnalyzer/auditData"];
      // console.log(newAuditData, "newAuditData");
    },
    async onClickAnalyzeButton() {
      this.$store.commit("coreValuesAnalyzer/doSaveValues", true);
      this.anylzyeSubmitedValue();
    },
    async anylzyeSubmitedValue() {
      this.removeNullValue();
      let valueText = "";
      for (let i = 0; i < this.coreValue.length; i++) {
        valueText += this.coreValue[i].value;
        if (i < this.coreValue.length - 1) {
          valueText += ",";
        }
      }
      this.$router.push({ query: { tags: valueText } });

      if (this.coreValue.length && this.coreValue[0].value) {
        // console.log("analyze(): before");
        this.analyzerShowing = true;
        await this.analyze();
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.setHeightOfElementByRef();
        }, 1000);
        setTimeout(() => {
          this.setHeightOfElementByRef();
        }, 3300);
        setTimeout(() => {
          this.setHeightOfElementByRef();
        }, 6600);
        // console.log("analyze(): after");
        // console.log(this.coreValue);
      } else if (this.coreValue.length === 0) {
        this.coreValue.push({
          value: "",
        });
      }
    },
    setHeightOfElementByRef() {
      for (const i in this.tabs) {
        const tab = this.tabs[i];
        const ref = tab.ref;
        const element = (this as any).$refs[ref];
        if (element) {
          this.tabs[i].height = element.offsetTop - 100;
        }
      }
    },
    matchingCoreValue(value: string) {
      const results = this.coreValue.filter((d) => {
        return d.value === value;
      });
      return results.length ? "active" : "";
    },
    imagePathAnalysisSection(name: string) {
      return require("@/assets/image/" + name + ".png") || "";
    },
    getRedirectUrl() {
      const redirectUrl = window.location.href.replace(
        "/core-values-analyzer",
        "/core-values"
      );
      return redirectUrl;
    },
    gotoLoginPage() {
      // console.log("gotoLoginPage()");
      this.$store.commit("auth/redirectUrl", this.getRedirectUrl(), {
        root: true,
      });
      // TODO: use redirectUrl in Login URL (Standard Pattern)
      this.$router.push({ name: "Login" });
    },
    gotoSignupPage() {
      // console.log("gotoSignupPage()");
      this.$store.commit("auth/redirectUrl", this.getRedirectUrl(), {
        root: true,
      });
      // TODO: use redirectUrl in Login URL (Standard Pattern)
      this.$router.push({ name: "Signup" });
    },
    init() {
      // init default inputs from url params and first result page
      if (this.$route.query.tags) {
        const tags = String(this.$route.query.tags).split(",");
        if (tags.length) {
          for (const i in tags) {
            this.addTagToCoreValue(tags[i]);
          }
          this.anylzyeSubmitedValue();
        }
      }
    },
    planeholder(index: number) {
      const planeholder = [
        "Your First Core Value",
        "Your Second Core Value",
        "Your Third Core Value",
        "Your Fourth Core Value",
        "Your Fifth Core Value",
        "Your Sixth Core Value",
        "Your Seventh Core Value",
        "Your Eighth Core Value",
      ];
      const planeholderTh = [
        "ค่านิยมลำดับที่หนึ่ง",
        "ค่านิยมลำดับที่สอง",
        "ค่านิยมลำดับที่สาม",
        "ค่านิยมลำดับที่สี่",
        "ค่านิยมลำดับที่ห้า",
        "ค่านิยมลำดับที่หก",
        "ค่านิยมลำดับที่เจ็ด",
        "ค่านิยมลำดับที่แปด",
      ];
      if ((this as any).$t("lang_code") == "th") {
        return planeholderTh[index];
      }
      return planeholder[index];
    },
    pdfDownloadBgColor(): any {
      // opacity 10%
      const bgColor = this.companyColor;
      // @see https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
      const hexToRgbA = (hex: string) => {
        let c: any;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
          c = hex.substring(1).split("");
          if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
          }
          c = "0x" + c.join("");
          return (
            "rgba(" +
            [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
            ",0.1)"
          );
        }
        console.log("Bad Hex");
        // throw new Error("Bad Hex");
      };
      return {
        backgroundColor: hexToRgbA(bgColor),
      };
    },
  },
  destroy() {
    window.removeEventListener("scroll", this.updateActiveByScroll);
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "cc3a4a7a": (_ctx.companyColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__