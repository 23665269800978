import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-439abd75"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box-title" }
const _hoisted_2 = {
  key: 0,
  class: "core-value-conten"
}
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "border-box" }
const _hoisted_5 = { class: "fs-18 mb-16 opa-02" }
const _hoisted_6 = { class: "guidance-card" }
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "card-des" }
const _hoisted_9 = {
  key: 1,
  class: "core-value-conten"
}
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "border-box" }
const _hoisted_12 = { class: "fs-18 mb-16" }
const _hoisted_13 = { class: "card-title" }
const _hoisted_14 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_masonry_wall = _resolveComponent("masonry-wall")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Microlearning")), 1),
    (!_ctx.microlearningLoaded || _ctx.locked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("coreValuesAnalyzer.These_micro_learning_content")), 1),
              _createVNode(_component_masonry_wall, {
                items: ['1', '2'],
                "ssr-columns": 2,
                "column-width": 350,
                gap: 16
              }, {
                default: _withCtx(({ item, index }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["guidance-box", item + index])
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", {
                          class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 br-4"]),
                          style: {"width":"40%"}
                        }, "   ", 2)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i2) => {
                          return _createElementVNode("div", {
                            key: i2 + '_mock_text',
                            class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 mt-2 br-4"]),
                            style: {"width":"100%"}
                          }, "   ", 2)
                        }), 64)),
                        _createElementVNode("div", {
                          class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 mt-2 br-4"]),
                          style: {"width":"80%"}
                        }, "   ", 2)
                      ])
                    ])
                  ], 2)
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("coreValuesAnalyzer.These_micro_learning_content")), 1),
              _createVNode(_component_masonry_wall, {
                items: _ctx.microlearningData.microlearning,
                "ssr-columns": 1,
                "column-width": 350,
                gap: 16
              }, {
                default: _withCtx(({ item, index }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["guidance-card", index])
                  }, [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(item.mricoLabel), 1),
                    _createElementVNode("div", {
                      class: "card-des",
                      innerHTML: _ctx.nl2br(item.mricoDescription)
                    }, null, 8, _hoisted_14)
                  ], 2)
                ]),
                _: 1
              }, 8, ["items"])
            ])
          ])
        ]))
  ], 64))
}